<style scoped lang='scss'>
.swiper-container{
  width: 1340px;
}
.BoxPadding{
  padding-top: 125px;
  padding-bottom:100px;
}
@keyframes widToMin
{
  0% {width: 1300px;}
  100% {width: 0px;}
}
@-webkit-keyframes widToMin
{
  0% {width: 1300px;}
  100% {width: 0px;}
}

.swiper-buttons{
  color: #666;
}
.index_banner{
  width:100%;
  min-width:1400px;
  min-height:335.42px;
  position: relative;
  .banner{ width:100%; }
  .banner_title{
    text-align: center;
    position: absolute;
    font-size: $Size48;
    color: white;
    width: 100%;
    top: calc(50% - 32px);
  }
}
.companyLntroduce{
  background-color: white;
  padding-top: 100px;
  padding-bottom: 90px;
  .introduceWz{
    margin-left: 40px;
    height: 300px;
    flex: 1;
    .introduceWz-title{
      font-size: $Size36;
      margin-bottom: 35px;
    }
    .introduceDetail{
      font-size: $Size18;
      line-height: 34px;
    }
  }
  .introduceActive{
    margin: 34px auto 80px;
  }
}
.introduceCompany{
  .intrComp-title{
    background:url('../assets/images/story/company.png') no-repeat center;
    font-size: $Size24;
    color: $indexColor999;
    margin-bottom: 50px;
  }
  .intrCompAdress{font-size: $Size20;}
  .intrCompName,.intrCompUrl{font-size: $Size18;color: $footerColor666;}
  .intrCompName{margin-top: 10px;}
  .intrCompUrl{margin-top: 5px;}
}

.guide-objective{
  background-color: #f4f4f4;
  padding-top: 95px;
  .guide-objective-t{margin-bottom: 55px;}
  .guide{
    margin-bottom: 25px;
    .guide-wz{
      width: 180px;
      height: 58px;
      line-height: 58px;
      font-size: $Size36;
      background-color: #489fc3;
      color: white;
      position: absolute;
      top: 0;
      left: 0;
    }
    .guide-rull{
      position: absolute;
      font-size: 30px;
      color: white;
    }
    .guide-rull1{ top: 181px; left: 611px; }
    .guide-rull2{ top: 343px; left: 890px; }
    .guide-rull3{ top: 343px; left: 327px; }
  }
  .objective{
    .objective-wz{
      width: 180px;
      height: 58px;
      line-height: 58px;
      font-size: $Size36;
      background-color: #2179bb;
      color: white;
      margin-bottom: 35px;
    }
    .objectiveItem{
      color: white;
      width: 240px;
      height: 120px;
      padding: 22px 0;
      overflow: hidden;
      position: absolute;
      top: 94px;
      .itemLine{
        line-height: 38px;
        font-size: $Size24;
      }
    }
    .objectiveItem1{
      left: 140px;
      width: 214px;
    }
    .objectiveItem2{
      left: 572px;
      width: 185px;
    }
    .objectiveItem3{
      left: 996px;
    }
  }
}
.developmenet{
  background-color: #fff;
  .developmenet-t{
    margin-bottom: 100px;
  }
  .developmenet-main{
    position: relative; min-width: 1400px;
    .developmenet-bg{width: 1300px;}
    .developmenet-modal{
      position: absolute;
      width: 1400px;
      top: 0;
      bottom: 0;
      right: 0px;
      background-color: white;
      z-index: 8;
    }
    .developmenet-modal.active{
      animation:widToMin 4s;
      -moz-animation:widToMin 4s; /* Firefox */
      -webkit-animation:widToMin 4s; /* Safari and Chrome */
      -o-animation:widToMin 4s; /* Opera */
      animation-iteration-count:1;/*动画只执行一次*/
      -moz-animation-iteration-count:1;
      -webkit-animation-iteration-count:1;
      -o-animation-iteration-count:1;
      animation-fill-mode:forwards;
    }
  }
  .timeItem{
    position: absolute;
    .item{ font-size: $Size24; line-height: 30px;}
    .event{ font-size: $Size18; line-height: 30px;}
  }
  .timeItem1{
    left: 170px;
    bottom: 93px;
  }
  .timeItem2{
    left: 275px;
    top: 98px;
  }
  .timeItem3{
    left: 565px;
    bottom: -3px;
  }
  .timeItem4{
    left: 755px;
    top: 128px;
  }
  .timeItem5{
    left: 985px;
    bottom: 122px;
  }
  .timeItem6{
    left: 1230px;
    top: 12px;
  }

}

.honor{
  overflow: hidden;
  padding-bottom: 0;
  .honor_ms{
    margin-top: 20px;
    margin-bottom: 90px;
  }
  .honor_bg{
    position: relative;
  }
  .honor_bg img{
    width: 100%;
  }

  .honorList{
    width: 1400px;
    background-color: white;
    padding: 30px;
    margin: 0 auto;
    position: relative;
    .swiper-slide{
      width: 268px !important;
      img{
      margin: 0 auto;
      cursor: pointer;
    }
    }
    .swiper-button-prev{
      top: 174px;
      left: calc(50% - 747px);
    }
    .swiper-button-next{
      top: 174px;
      left: calc(50% + 721px);
    }
  }

}

</style>

<template>
  <div id="story" ref="storyMain">
      <div class="index_banner">
        <img class="banner" src="@assets/images/story/banner.jpg" alt="">
        <div class="banner_title wow fadeInUp">我们的故事 &nbsp;越来越精彩</div>
      </div>
      <!-- 果果慧科技介绍 -->
      <div class="companyLntroduce BoxPadding" ref="story">
        <div class="introduce width acea-row row-left">
          <img src="@assets/images/story/storyLogo.png" alt="" class="wow fadeInUp" data-wow-delay="0.2s">
          <div class="introduceWz">
            <div class="introduceWz-title weight wow fadeInUp" data-wow-delay="0.3s">果果慧科技</div>
            <div class="introduceDetail wow fadeInUp" data-wow-delay="0.4s">
              深圳果果慧科技有限公司（Aifrutech Co.,Ltd.）成立于2017年，
              是一家专业的智慧硬件方案服务商，服务领域覆盖智慧图形图像类产品、游戏周边产品、物联网（IoT）、智慧农业等。
              公司不断地结合市场的实际需求，提供需求分析，外观设计，结构设计，软件开发，硬件设计，试产，测试认证和量产的一站式服务。
              在发展中不断的提升和壮大，开始致力于自我品牌产品的研发，生产和销售，不断打造果果慧独特的品牌力量。
              目前已与多家知名企业建立了密切的合作关系，充分利用国内外资源，大力开拓国际市场。
            </div>
          </div>
        </div>
        <div class="introduceActive width acea-row row-between-wrapper">
          <img src="@assets/images/story/active01.png" alt="" class="wow fadeInUp" data-wow-delay="0.4s">
          <img src="@assets/images/story/active02.png" alt="" class="wow fadeInUp" data-wow-delay="0.6s">
          <img src="@assets/images/story/active03.png" alt="" class="wow fadeInUp" data-wow-delay="0.8s">
          <img src="@assets/images/story/active04.png" alt="" class="wow fadeInUp" data-wow-delay="1s">
        </div>
        <div class="introduceCompany width">
          <div class="intrComp-title textCenter wow fadeInUp" data-wow-delay="0.6s">关联公司</div>
          <div class="intrCompList width acea-row row-between-wrapper">
            <div class="intrCompListItem" v-for="(item, index) in intrCompListItem" :key="index">
              <div class="intrCompAdress weight wow fadeInUp" data-wow-delay="0.6s">{{item.intrCompAdress}}</div>
              <div class="intrCompName wow fadeInUp" data-wow-delay="0.7s">{{item.intrCompName}}</div>
              <div class="intrCompUrl wow fadeInUp" v-if="item.intrCompUrl" data-wow-delay="0.7s">URL：{{item.intrCompUrl}}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 行动指南和工作宗旨 -->
      <div class="guide-objective BoxPadding" ref="guideObjective">
        <div class="guide-objective-t weight textCenter indexMainTitle wow fadeInUp" data-wow-delay="0.2s">搭建技术桥梁 &nbsp;创造幸福社会</div>
        <div class="guide width acea-row row-center">
          <img src="@assets/images/story/guide.png" alt="" class="guide-img wow fadeInUp" data-wow-delay="0.6s">
          <div class="guide-wz weight textCenter wow fadeInUp" data-wow-delay="0.4s">行动指南</div>
          <div class="guide-rull guide-rull1 wow fadeInUp" data-wow-delay="0.6s">尽诚以期合作</div>
          <div class="guide-rull guide-rull2 wow fadeInUp" data-wow-delay="0.4s">尽责以期成果</div>
          <div class="guide-rull guide-rull3 wow fadeInUp" data-wow-delay="0.4s">尽信以期发展</div>
        </div>
        <div class="objective width">
          <div class="objective-wz weight textCenter wow fadeInUp" data-wow-delay="0.4s">工作宗旨</div>
          <div class="objective-mb">
            <img src="@assets/images/story/objective.png" alt="" class="objective-img wow fadeInUp" data-wow-delay="0.4s">
            <div class="objectiveItem objectiveItem1">
              <div class="itemLine wow fadeInUp" data-wow-delay="0.4s">不断导入新灵感</div>
              <div class="itemLine wow fadeInUp" data-wow-delay="0.5s">持续创造新价值</div>
            </div>
            <div class="objectiveItem objectiveItem2">
              <div class="itemLine wow fadeInUp" data-wow-delay="0.4s">不忘革新精神</div>
              <div class="itemLine wow fadeInUp" data-wow-delay="0.5s">满足社会需求</div>
            </div>
            <div class="objectiveItem objectiveItem3">
              <div class="itemLine wow fadeInUp" data-wow-delay="0.4s">持续追求打破</div>
              <div class="itemLine wow fadeInUp" data-wow-delay="0.5s">固定观念的经营模式</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 发展历程 -->
      <div class="developmenet BoxPadding" ref="developmenet">
        <div class="developmenet-t weight textCenter indexMainTitle wow fadeInUp" data-wow-delay="0.2s">发展历程</div>
        <div class="developmenet-main width acea-row row-center">
          <img src="@assets/images/story/development-course.png" alt="" class="developmenet-bg">
          <!-- 遮挡板开始 -->
          <div class="developmenet-modal" :class="{active:active}"></div>
          <!-- 遮挡板结束 -->
          <div class="timeItem timeItem1 wow fadeInUp" data-wow-delay="1s">
            <div class="item weight">2012/10</div>
            <div class="event">关联公司-日本瑞起在横滨成立</div>
          </div>
          <div class="timeItem timeItem2 wow fadeInUp" data-wow-delay="1.5s">
            <div class="item weight">2013/11</div>
            <div class="event">关联公司-香港鸿屹在香港成立</div>
          </div>
          <div class="timeItem timeItem3 wow fadeInUp" data-wow-delay="1.8s">
            <div class="item weight">2017/03</div>
            <div class="event">深圳果果慧科技有限公司成立</div>
            <div class="event">关联公司-珠海佳果慧在珠海成立</div>
          </div>
          <div class="timeItem timeItem4 wow fadeInUp" data-wow-delay="2.4s">
            <div class="item weight">2017/04</div>
            <div class="event">全志授权代理消费类电子产品研发</div>
          </div>
          <div class="timeItem timeItem5 wow fadeInUp" data-wow-delay="2.6s">
            <div class="item weight">2017/06</div>
            <div class="event">农业loT产品研发</div>
          </div>
          <div class="timeItem timeItem6 wow fadeInUp" data-wow-delay="3.2s">
            <div class="item weight">2018/09</div>
            <div class="event">自主芯片Z7213正式发售</div>
          </div>
        </div>
      </div>
      <!-- 果果慧荣誉 -->
      <div class="honor textCenter BoxPadding" ref="honor">
        <div class="honor_title indexMainTitle weight margin-bottom wow fadeInUp" data-wow-delay="0.2s">果果慧荣誉</div>
        <div class="honor_ms indexMainTitle_second wow fadeInUp" data-wow-delay="0.25s">果果慧的每一次成长和蜕变，都离不开每一位风雨兼程使命必达的果果慧牛人</div>
        <div class="textCenter wow fadeInUp" data-wow-delay="0.3s">高新技术企业</div>
        <div class="honor_bg wow fadeInUp" data-wow-delay="0.3s">
          <img v-lazy="honorBgImg" alt="" ref="honorBgImgS">
        </div>
      </div>
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    let _that = this;
    return {
      url: '',// 当前页面地址
      // 关联公司列表
      intrCompListItem:[
        {
          intrCompAdress: '日本',
          intrCompName: '株式会社瑞起（ZUIKI Inc.）',
          intrCompUrl: 'www.zuiki.co.jp',
        },
        {
          intrCompAdress: '珠海',
          intrCompName: '佳果慧科技有限公司（Aifrutech Zhuhai Co.,LTD）',
          intrCompUrl: '',
        },
        {
          intrCompAdress: '香港',
          intrCompName: '鸿屹有限公司（AXELINXCo.,LIMITED）',
          intrCompUrl: '',
        }
      ],
      active: false,// 执行展示发展历程的动画
      honorBgImg: require('../assets/images/story/honor.png'),
    }
  },
  computed: {},
  created () {
  },
  watch: {
    $route(){
      let _that = this;
      if( _that.$route.query.type ){
        let index = _that.$route.query.type.indexOf('aifrutech');
        let link = index<0?_that.$route.query.type:_that.$route.query.type.substr(0,index);
        let offetTop = _that.$refs[link].offsetTop-66;
        window.scrollTo({ top: offetTop,left: 0,behavior: 'smooth' });
      }else{
        _that.type = ''
      }
    },
    // 监听一直获取高新技术这张图片的宽高度
  },
  mounted(){
    window.scrollTo({top: 0,left: 0});
    let _that = this;
    new this.$WOW.WOW({offset: '40'}).init();
    if(_that.$route.query.type){
      let index = _that.$route.query.type.indexOf('aifrutech');
      let link = index<0?_that.$route.query.type:_that.$route.query.type.substr(0,index);
      setTimeout(function(){
        let offetTop = _that.$refs[link].offsetTop-66;
        console.log(offetTop,222);
        window.scrollTo({top: offetTop,left: 0,behavior: 'smooth'});
      },400)
    }
    window.addEventListener('scroll',this.handleScroll,true)

  },
  beforeDestroy () {
    // 移除监听
    window.removeEventListener('scroll',this.handleScroll,true)
  },
  methods: {
    handleScroll(){
      let scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
      if(scrollTop >= this.$refs['developmenet'].offsetTop-66){
        this.active = true;
      }
    }
  },

}

</script>
